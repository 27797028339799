<template>
  <div>
    <v-container>
      <v-row v-if="folioSeleccionado != ''">
        <v-col cols="12">
          <h5 style="font-weight: 400">
            {{ folioSeleccionado }}
          </h5>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="6">
          <v-text-field
            v-model="folioBuscar"
            label="Folio Conteo"
            clearable
            dense
            hide-details
            :color="color"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-btn v-on:click="BuscarFolio()" block :color="color" dark>
            Buscar
          </v-btn>
        </v-col>
      </v-row>
      <v-row style="padding-bottom: 18px">
        <v-col cols="12">
          <v-text-field
            v-model="codigoBarras"
            label="Codigo de barras"
            clearable
            dense
            ref="codigoBarra"
            hide-details
            :color="color"
            outlined
            :readonly="isKeyboardDisabled"
            @keydown.enter.prevent="BuscaArtCodB()"
            @focus="ocultaTeclado"
          ></v-text-field>
        </v-col>
        <v-col class="mensajes" cols="12">
          <h6 style="font-weight: 400">{{ codigoBarrasMos }}</h6>
        </v-col>
        <v-col class="mensajes" cols="12">
          <h6 style="font-weight: 400">{{ itemNoMostrar }}</h6>
        </v-col>
        <v-col class="mensajes" cols="12">
          <h6 style="font-weight: 400">{{ descripcionArt }}</h6>
        </v-col>
      </v-row>
      <v-divider style="border-width: thin 0 2px 0"></v-divider>
      <v-row style="padding-top: 14px; padding-bottom: 18px">
        <v-col cols="12">
          <v-text-field
            v-model="itemNoManual"
            label="Codigo Jomar (Opcional)"
            clearable
            dense
            hide-details
            :color="color"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-btn v-on:click="BuscarArticulo()" block :color="color" dark>
            Buscar
          </v-btn>
        </v-col>
      </v-row>
      <v-divider style="border-width: thin 0 2px 0"></v-divider>
      <v-row style="padding-top: 18px; padding-bottom: 18px">
        <v-col style="padding-bottom: 5px" cols="12">
          <v-text-field
            v-model="cantidad"
            label="Cantidad: (Opcional)"
            clearable
            dense
            type="number"
            hide-details
            :color="color"
            outlined
          ></v-text-field>
        </v-col>
        <v-col style="padding-top: 0" cols="12">
          <v-checkbox
            v-model="mulPiezas"
            label="Multiplicar por piezas"
            dense
            hide-details
          ></v-checkbox>
        </v-col>
        <v-col style="padding-top: 5px" cols="12">
          <v-text-field
            v-model="piezas"
            label="Piezas"
            clearable
            dense
            type="number"
            hide-details
            :color="color"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-btn v-on:click="AgregarCantidad()" block :color="color" dark>
            Aceptar
          </v-btn>
        </v-col>
      </v-row>
      <v-divider style="border-width: thin 0 2px 0"></v-divider>
      <v-row style="padding-top: 18px">
        <v-col cols="12">
          <v-btn v-on:click="CerrarConteo()" block :color="color" dark>
            Cerrar Conteo
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="visibleFCerrar">
        <v-col cols="6">
          <v-text-field
            v-model="folioCerrar"
            clearable
            dense
            type="number"
            hide-details
            :color="color"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-btn v-on:click="GuardaConteo()" block :color="color" dark>
            Aceptar
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      persistent
      transition="fade-transition"
      v-model="dAlert"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <v-icon> {{ mDAIcono }} </v-icon>
          {{ mDAtituloAlert }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="padding-top: 10px"> {{ mDAError }} </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="text-h5 grey lighten-2">
          <v-spacer></v-spacer>
          <v-btn :color="color" text @click="dAlert = false"> Aceptar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      persistent
      transition="fade-transition"
      v-model="dAlertSiNo"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <v-icon> {{ mDAIcono }} </v-icon>
          {{ mDAtituloAlert }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="padding-top: 10px"> {{ mDAError }} </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="text-h5 grey lighten-2">
          <v-btn
            :color="color"
            text
            v-if="mostrarBtn == 1"
            @click="BuscaArticuloEscaneado(artActual, CBActual)"
          >
            Si
          </v-btn>
          <v-btn
            :color="color"
            text
            v-else-if="mostrarBtn == 2"
            @click="BuscaArticuloManual(artActual)"
          >
            Si
          </v-btn>
          <v-btn
            :color="color"
            text
            v-else-if="mostrarBtn == 3"
            @click="ReanudarConteo()"
          >
            Si
          </v-btn>
          <v-btn
            :color="color"
            text
            v-else-if="mostrarBtn == 4"
            @click="CrearXML()"
          >
            Si
          </v-btn>
          <v-btn
            :color="color"
            text
            v-else-if="mostrarBtn == 5"
            @click="EliminaConteoCache()"
          >
            Si
          </v-btn>
          <v-btn
            :color="color"
            text
            v-else-if="mostrarBtn == 6"
            @click="folioARecontar()"
          >
            Si
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="mostrarBtn == 1 || mostrarBtn == 2 || mostrarBtn == 6"
            :color="color"
            text
            @click="dAlertSiNo = false"
          >
            No
          </v-btn>
          <v-btn
            v-else-if="mostrarBtn == 3"
            :color="color"
            text
            @click="VolverAPreguntar()"
          >
            No
          </v-btn>
          <v-btn
            v-else-if="mostrarBtn == 4"
            :color="color"
            text
            @click="limpiaFolio()"
          >
            No
          </v-btn>
          <v-btn
            v-else-if="mostrarBtn == 5"
            :color="color"
            text
            @click="ReanudarConteo()"
          >
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      persistent
      transition="fade-transition"
      v-model="dAlertF"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <v-icon> {{ mDAIcono }} </v-icon>
          {{ mDAtituloAlert }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="padding-top: 10px"> {{ mDAError }} </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="text-h5 grey lighten-2">
          <v-spacer></v-spacer>
          <v-btn :color="color" text @click="LimpiarTodo()"> Aceptar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import sound from "../assets/error.wav";
export default {
  name: "ConteoInventario",
  data: () => ({
    folioConteo: null,
    folioBuscar: null,
    codigoBarras: "",
    codigoBarrasMos: "",
    itemNoManual: "",
    itemNoMostrar: "",
    descripcionArt: "",
    cantidad: 0,
    mulPiezas: false,
    piezas: 0,
    folioCerrar: null,
    visibleFCerrar: false,
    codigoBarraFocus: null,
    CBPass: "",
    dAlert: false,
    mDAError: "",
    mDAIcono: "",
    mDAtituloAlert: "",
    listArticulos: [],
    idCategoria: "",
    artActual: {},
    CBActual: "",
    dAlertSiNo: false,
    mostrarBtn: 1,
    folioManual: 1, //1 es manual, 0 es seleccionado de la pantalla conteospendientes
    dAlertF: false,
    isKeyboardDisabled: false,
    contadorParaRespaldar: 0,
    folioBuscado: "",
  }),
  computed: {
    ...mapState(["color", "secondColor", "url"]),
    isAuthorized: {
      get: function () {
        return this.$store.state.isAuthorized;
      },
      set: function (val) {
        this.$store.state.isAuthorized = val;
      },
    },
    userInfo: {
      get: function () {
        return this.$store.state.userInfo;
      },
      set: function (val) {
        this.$store.state.userInfo = val;
      },
    },
    token: {
      get: function () {
        return this.$store.state.token;
      },
      set: function (val) {
        this.$store.state.token = val;
      },
    },
    dbSelected: {
      get: function () {
        return this.$store.state.dbSelected;
      },
      set: function (val) {
        localStorage.setItem("dbSelected", val);
        this.$store.commit("setdbSelected", val);
      },
    },
    folioSeleccionado: {
      get: function () {
        return this.$store.state.folioSeleccionado;
      },
      set: function (val) {
        this.$store.commit("setFolioSeleccionado", val);
      },
    },
    navbarToggle: {
      get: function () {
        return this.$store.state.navbarToggle;
      },
      set: function (val) {
        localStorage.setItem("navbarToggle", val);
        this.$store.commit("setNavbarToggle", val);
      },
    },
  },
  async mounted() {
    const credenciales = await JSON.parse(localStorage.getItem("credenciales"));
    if (credenciales != null) {
      this.token = credenciales.token;
      this.userInfo = credenciales.userInfo;
      this.isAuthorized = credenciales.isAuthorized;
    }
    const dbSelect = localStorage.getItem("dbSelected");
    if (dbSelect != null) {
      this.dbSelected = parseInt(dbSelect);
    }
    this.codigoBarraFocus = this.$refs.codigoBarra;
    this.ocultaTeclado();
    const FolioSelected = await localStorage.getItem("FolioSelected");
    if (FolioSelected != null) {
      this.folioSeleccionado = FolioSelected;
    } else {
      this.folioSeleccionado = "";
    }
    const ListaArticulosCache = await JSON.parse(
      localStorage.getItem("ListaArticulosCache")
    );
    if (ListaArticulosCache != null) {
      this.mostrarBtn = 3;
      this.mDAIcono = "mdi-alert-circle";
      this.mDAtituloAlert = "Reanudar Conteo";
      this.mDAError = "Hay un conteo pendiente. ¿Desea reanudar el conteo?";
      this.dAlertSiNo = true;
    }
  },
  methods: {
    ...mapActions([
      "TraeInfoArticuloXCB",
      "TraeInfoArticuloXItemno",
      "ConsultaFolioConteoExiste",
      "GuardaListaCache",
      "GuardarConteo",
      "GuardaRespaldoListaCache",
    ]),
    ...mapMutations(["snackbar"]),
    async BuscarFolio() {
      if (this.folioBuscar == null) {
        this.snackbar({
          snackbar: true,
          text: "No se ingreso ningun folio para buscar.",
        });
        return;
      }
      const folioExiste = await this.ConsultaFolioConteoExiste({
        sucursal: this.userInfo.sucursal,
        folioConteo: this.folioBuscar,
        db: this.dbSelected,
      });
      if (folioExiste == null) {
        this.mDAIcono = "mdi-alert";
        this.mDAtituloAlert = "Error";
        this.mDAError =
          "No se encontró el folio ingresado, favor de verificar.";
        this.dAlert = true;
        return;
      }
      this.folioBuscado = folioExiste;
      this.mDAIcono = "mdi-alert-circle";
      this.mDAtituloAlert = "Aviso";
      this.mDAError = "¿El folio a recontar es: " + folioExiste + "?";
      this.mostrarBtn = 6;
      this.dAlertSiNo = true;
    },
    folioARecontar() {
      this.folioSeleccionado = this.folioBuscado;
      localStorage.setItem("FolioSelected", this.folioSeleccionado);
      this.folioBuscar = null;
      this.dAlertSiNo = false;
    },
    ocultaTeclado() {
      this.isKeyboardDisabled = true;
      this.codigoBarraFocus.$el.querySelector("input").focus();
      setTimeout(() => {
        this.isKeyboardDisabled = false; // Vuelve a hacer editable el campo, pero sin abrir el teclado
      }, 100);
    },
    errorSound() {
      var audio = new Audio(sound);
      audio.play();
    },
    async BuscarArticulo() {
      const detener = this.activarAlertaRespaldarConteo();
      if (detener) {
        return;
      }
      if (this.itemNoManual == "") {
        this.snackbar({
          snackbar: true,
          text: "No se ingreso ningun articulo para buscar manual.",
        });
        return;
      }
      const artManual = await this.TraeInfoArticuloXItemno(this.itemNoManual);
      if (artManual != null) {
        if (this.idCategoria != artManual.IdCategoria) {
          this.idCategoria = artManual.IdCategoria;

          this.mostrarBtn = 2;
          this.artActual = artManual;
          this.mDAIcono = "mdi-alert-circle";
          this.mDAtituloAlert = "Error";
          this.mDAError =
            "Estas contando la categoria: " +
            artManual.Categoria +
            ", ¿Deseas continuar contando?.";
          this.dAlertSiNo = true;
        } else {
          if (
          this.cantidad > 1 &&
          this.itemNoMostrar.trim() !== artManual.Itemno.trim()
        ) {
          this.noADadoAceptar();
          return;
        }
          this.BuscaArticuloManual(artManual);
        }
      } else {
        this.itemNoMostrar = "";
        this.codigoBarrasMos = "";
        this.descripcionArt = "";
        this.errorSound();
        this.mDAIcono = "mdi-alert";
        this.mDAtituloAlert = "Error";
        this.mDAError = "No se encontro el articulo ingresado.";
        this.dAlert = true;
      }
    },
    CerrarConteo() {
      if (this.listArticulos.length == 0) {
        this.mDAIcono = "mdi-alert";
        this.mDAtituloAlert = "Error";
        this.mDAError = "No se a realizado ningún conteo.";
        this.dAlert = true;
        return;
      }
      if (this.folioSeleccionado != "") {
        this.folioManual = 0;
        const splitarray = this.folioSeleccionado.split("-");
        this.folioCerrar = splitarray[0];
      }
      this.visibleFCerrar = true;
    },
    BuscaArtCodB() {
      const detener = this.activarAlertaRespaldarConteo();
      if (detener) {
        return;
      }
      if (this.codigoBarras == "") {
        return;
      }
      this.codigoBarrasMos = this.codigoBarras;
      this.TraerArticulo();
      this.codigoBarras = "";
      this.ocultaTeclado();
    },
    async TraerArticulo() {
      const CBNow = this.codigoBarras.trim();
      if (
        this.CBPass.toUpperCase() === CBNow.toUpperCase() &&
        this.itemNoMostrar != ""
      ) {
        this.cantidad += this.piezas;
        return;
      }
      const articuloScan = await this.TraeInfoArticuloXCB(CBNow);

      if (!articuloScan) {
        this.errorSound();
        this.mDAIcono = "mdi-alert";
        this.mDAtituloAlert = "Error";
        this.mDAError = "No se encontro el articulo escaneado.";
        this.dAlert = true;
        return;
      }

      if (this.CBPass === "") {
        this.mDAIcono = "mdi-alert-circle";
        this.mDAtituloAlert = "Categoria";
        this.mDAError =
          "Estas contando la categoria: " + articuloScan.Categoria + ".";
        this.dAlert = true;
        this.listArticulos.push({
          ArticuloId: articuloScan.Itemno.trim(),
          Descripcion: articuloScan.Descripcion,
          Cantidad: 0,
          Escaneado: 1,
          Existencia: articuloScan.Existencia,
        });
        this.itemNoMostrar = articuloScan.Itemno.trim();
        this.descripcionArt = articuloScan.Descripcion;
        this.CBPass = CBNow;
        this.piezas = articuloScan.Caja;
        this.cantidad = articuloScan.Caja;
        this.idCategoria = articuloScan.IdCategoria;
      } else {
        if (
          this.cantidad > 1 &&
          this.itemNoMostrar.trim() !== articuloScan.Itemno.trim()
        ) {
          this.noADadoAceptar();
          return;
        }
        if (this.idCategoria !== articuloScan.IdCategoria) {
          this.idCategoria = articuloScan.IdCategoria;
          this.artActual = articuloScan;
          this.CBActual = CBNow;
          this.mDAIcono = "mdi-alert-circle";
          this.mDAtituloAlert = "Error";
          this.mDAError = `Estas contando la categoria: ${articuloScan.Categoria}, ¿Deseas continuar contando?.`;
          this.mostrarBtn = 1;
          this.dAlertSiNo = true;
        } else {
          this.BuscaArticuloEscaneado(articuloScan, CBNow);
        }
      }
    },
    BuscaArticuloEscaneado(articuloScan, CBNow) {
      let existe = false;
      for (const articulo of this.listArticulos) {
        if (articulo.ArticuloId.trim() == articuloScan.Itemno.trim()) {
          articulo.Escaneado = 1;
          existe = true;
          break;
        }
      }
      if (this.itemNoMostrar.trim() != articuloScan.Itemno.trim()) {
        this.cantidad = 0;
      }
      if (existe) {
        this.cantidad = this.cantidad + articuloScan.Caja;
      } else {
        this.listArticulos.push({
          ArticuloId: articuloScan.Itemno.trim(),
          Descripcion: articuloScan.Descripcion,
          Cantidad: 0,
          Escaneado: 1,
          Existencia: articuloScan.Existencia,
        });
        this.cantidad = articuloScan.Caja;
      }
      this.itemNoMostrar = articuloScan.Itemno.trim();
      this.descripcionArt = articuloScan.Descripcion;
      this.piezas = articuloScan.Caja;
      this.idCategoria = articuloScan.IdCategoria;
      this.CBPass = CBNow;
      this.dAlertSiNo = false;
    },
    BuscaArticuloManual(articuloManual) {
      let existe = false;
      for (const articulo of this.listArticulos) {
        if (articulo.ArticuloId.trim() == articuloManual.Itemno.trim()) {
          articulo.Escaneado = 0;
          existe = true;
          break;
        }
      }
      if (existe) {
        this.cantidad = 1;
      } else {
        this.listArticulos.push({
          ArticuloId: articuloManual.Itemno.trim(),
          Descripcion: articuloManual.Descripcion,
          Cantidad: 0,
          Escaneado: 0,
          Existencia: articuloManual.Existencia,
        });
        this.cantidad = 1;
      }
      this.itemNoMostrar = articuloManual.Itemno.trim();
      this.CBPass = "1";
      this.descripcionArt = articuloManual.Descripcion;
      this.piezas = 1;
      this.idCategoria = articuloManual.IdCategoria;
      this.dAlertSiNo = false;
    },
    AgregarCantidad() {
      if (this.itemNoMostrar != "" || this.codigoBarrasMos != "") {
        for (let i = 0; i < this.listArticulos.length; i++) {
          if (this.listArticulos[i].ArticuloId == this.itemNoMostrar) {
            let valor;
            if (this.mulPiezas) {
              valor = parseFloat(this.cantidad) * parseFloat(this.piezas);
            } else {
              valor = this.cantidad;
            }
            this.listArticulos[i].Cantidad =
              parseFloat(valor) + parseFloat(this.listArticulos[i].Cantidad);
            break;
          }
        }
        localStorage.setItem(
          "ListaArticulosCache",
          JSON.stringify(this.listArticulos)
        );
        this.mulPiezas = false;
        this.piezas = 0;
        this.itemNoMostrar = "";
        this.codigoBarrasMos = "";
        this.cantidad = 0;
        this.itemNoManual = "";
        this.descripcionArt = "";
        this.snackbar({
          snackbar: true,
          text: `Hay ${this.listArticulos.length} articulos en la lista.`,
        });
        this.contadorParaRespaldar = this.contadorParaRespaldar + 1;
        if (this.contadorParaRespaldar == 20) {
          const splitarray = this.folioSeleccionado.split("-");
          this.folioCerrar = splitarray[0];
          this.GuardaRespaldoListaCache({
            listado: JSON.stringify(this.listArticulos),
            folioConteo: this.folioCerrar,
            sucursal: this.userInfo.sucursal,
            idUsuario: this.userInfo.idUsuario,
            db: this.dbSelected,
          });
          this.contadorParaRespaldar = 0;
        }
        this.ocultaTeclado();
      } else {
        this.snackbar({
          snackbar: true,
          text: "No se ingreso ningun articulo.",
        });
      }
    },
    async ReanudarConteo() {
      const ListaArticulosCache = await JSON.parse(
        localStorage.getItem("ListaArticulosCache")
      );
      this.listArticulos = ListaArticulosCache;
      this.CBPass = "1";
      this.snackbar({
        snackbar: true,
        text: `Hay ${this.listArticulos.length} articulos en la lista.`,
      });
      this.dAlertSiNo = false;
    },
    EliminaConteoCache() {
      localStorage.removeItem("ListaArticulosCache");
      this.dAlertSiNo = false;
    },
    async GuardaConteo() {
      const folioExiste = await this.ConsultaFolioConteoExiste({
        sucursal: this.userInfo.sucursal,
        folioConteo: this.folioCerrar,
        db: this.dbSelected,
      });
      if (folioExiste == null) {
        this.mDAIcono = "mdi-alert";
        this.mDAtituloAlert = "Error";
        this.mDAError =
          "No se encontró el folio ingresado, favor de verificar.";
        this.dAlert = true;
        return;
      }
      if (this.folioManual == 0) {
        this.CrearXML();
      } else {
        this.mDAIcono = "mdi-alert-circle";
        this.mDAtituloAlert = "Aviso";
        this.mDAError = "El folio seleccionado es: " + folioExiste;
        this.mostrarBtn = 4;
        this.dAlertSiNo = true;
      }
    },
    async CrearXML() {
      const listaCache = await JSON.stringify(this.listArticulos);
      const respuesta = await this.GuardarConteo({
        IdUsuario: this.userInfo.idUsuario,
        Sucursal: this.userInfo.sucursal,
        listaArticulos: listaCache,
        FolioGen: this.folioCerrar,
        db: this.dbSelected,
      });
      if (respuesta != null) {
        if (respuesta.exito == 1) {
          this.mDAIcono = "mdi-alert-circle";
          this.mDAtituloAlert = "Folio";
          this.mDAError =
            "Se realizo el folio " + this.folioCerrar + " correctamente.";
          this.dAlertF = true;
        } else {
          this.mDAIcono = "mdi-alert";
          this.mDAtituloAlert = "Error";
          this.mDAError =
            "No se puede actualizar el conteo porque ya se genero un preajuste.";
          this.dAlertF = true;
        }
      }
    },
    limpiaFolio() {
      this.folioCerrar = "";
      this.dAlertSiNo = false;
    },
    LimpiarTodo() {
      localStorage.removeItem("ListaArticulosCache");
      localStorage.removeItem("FolioSelected");
      this.folioConteo = null;
      this.folioBuscar = null;
      this.codigoBarras = "";
      this.codigoBarrasMos = "";
      this.itemNoManual = "";
      this.itemNoMostrar = "";
      this.descripcionArt = "";
      this.cantidad = 0;
      this.mulPiezas = false;
      this.piezas = 0;
      this.folioCerrar = null;
      this.visibleFCerrar = false;
      this.codigoBarraFocus = null;
      this.CBPass = "";
      this.dAlert = false;
      this.mDAError = "";
      this.mDAIcono = "";
      this.mDAtituloAlert = "";
      this.listArticulos = [];
      this.idCategoria = "";
      this.artActual = {};
      this.CBActual = "";
      this.dAlertSiNo = false;
      this.mostrarBtn = 1;
      this.folioManual = 1;
      this.dAlertF = false;
      this.navbarToggle = 0;
      this.folioSeleccionado = "";
      this.$router.push({ name: "ConteosPendientes" });
    },
    VolverAPreguntar() {
      this.mostrarBtn = 5;
      this.mDAIcono = "mdi-alert-circle";
      this.mDAtituloAlert = "Reanudar Conteo";
      this.mDAError = "¿Esta seguro que NO QUIERE REANUDAR el conteo?";
    },
    activarAlertaRespaldarConteo() {
      if (this.folioSeleccionado == "") {
        this.errorSound();
        this.mDAIcono = "mdi-alert";
        this.mDAtituloAlert = "Error";
        this.mDAError =
          "No se a ingresado el folio que se quiere recontar, es necesario ingresarlo para que funcione el respaldo.";
        this.dAlert = true;
        return true;
      } else {
        return false;
      }
    },
    noADadoAceptar() {
      this.errorSound();
      this.mDAIcono = "mdi-alert";
      this.mDAtituloAlert = "Error";
      this.mDAError = "No a dado aceptar al articulo actual.";
      this.dAlert = true;
    },
  },
};
</script>
<style>
.mensajes {
  padding: 0 12px 0 12px;
}
</style>
